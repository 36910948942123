import {
  GET_REPORTS_DATA,
  GET_REPORTS_DATA_FAILED,
  GET_REPORTS_DATA_SUCCESS,
  GET_SALES_DATA,
  GET_SALES_DATA_FAILED,
  GET_SALES_DATA_SUCCESS,
  GET_TREND_REPORT_DETAILS,
  GET_TREND_REPORT_DETAILS_FAILED,
  GET_TREND_REPORT_DETAILS_SUCCESS,
  REMOVE_ALL_CHARTS,
  REMOVE_CHART,
  SAVE_REPORT,
  SAVE_REPORT_CONTENT,
  SAVE_REPORT_CONTENT_FAILED,
  SAVE_REPORT_CONTENT_SUCCESS,
  SAVE_REPORT_FAILED,
  SAVE_REPORT_SUCCESS,
  SET_ADD_NEW_CHART_IN_VALID,
  SET_ADD_NEW_CHART_VALID,
  SET_CHART_COMMENT_VALUE,
  SET_CHART_DATA,
  SET_CHART_DATA_LOADING,
  SET_CHART_LABELS,
  SET_LOCATIONS_OPTIONS,
  SET_MARKET_ANALYSIS_IS_VALID,
  SET_NEW_CHART,
  SET_NEW_REPROT_IN_VALID,
  SET_NEW_REPROT_VALID,
  SET_PRICE_BRACKET_OPTIONS,
  SET_REPORT_CONTENT,
  SET_REPORT_CONTENT_TITLE_IS_VALID,
  SET_REPORT_INFO,
  SET_REPORT_INFO_TITLE_IS_VALID,
  SET_REPORT_STEP,
  SET_SAVE_REPORT_ERROR_NULL,
  SET_SAVE_REPORT_SUCCESS_INVALID,
  SET_SHOW_TABLE_IS_VALID,
  SET_TRENDS_REPORTS_PAGINATION,
  SET_TREND_REPORT_DETAILS_NULL,
  DELETE_MULTIPLE_REPORTS,
  DELETE_MULTIPLE_REPORTS_SUCCESS,
  DELETE_MULTIPLE_REPORTS_FAILED,
  HIDE_DELETE_MULTIPLE_REPORTS_ERROR,
} from "redux/constants/Report";

import {
  BRACKET_ALL,
  CATEGORY_ALL,
  LOCATION_BY_PARISH,
  NUMBER_OF_SALES,
  PARISH_ALL,
  PRICE_BRACKET,
  YEARLY,
} from "constants/ReortContentConstants";
import {
  priceBracketOptions,
  reportCitiesOptions,
} from "configs/ReportContentConfig";

const chartInitObj = {
  title: "",
  category: CATEGORY_ALL,
  indicator: NUMBER_OF_SALES,
  locationOption: LOCATION_BY_PARISH,
  location: PARISH_ALL,
  bracketType: PRICE_BRACKET,
  bracket: BRACKET_ALL,
  timePeriod: YEARLY,
  fromDate: "2018",
  toDate: "2024",
  comment: "",
};
const reportInfoInit = {
  title: "",
  contact_name: "",
  contact_email: "",
  contact_phone: "",
};

const init = {
  reportsData: [],
  reportsDataLoading: false,
  reportsDataError: null,
  saveReportContentLoading: false,
  saveReportContentError: null,
  reportData: [],
  saveReportLoading: false,
  saveReportSuccessIsValid: false,
  saveReportError: null,
  reportsPagination: { current: 1, pageSize: 10 },
  salesData: null,
  salesDataLoading: false,
  salesDataError: null,
  newReportIsValid: false,
  chartCommentValue: "",
  addNewChartIsValid: false,
  locationOptionSelection: reportCitiesOptions,
  bracketTypeOptionSelection: priceBracketOptions,
  reportInfoTitleIsValid: true,
  reportContentTitleIsValid: true,
  step: 1,
  chartLabels: [],
  chartDataLoading: false,
  chartData: [],
  marketAnalysisIsValid: false,
  reportInfo: reportInfoInit,
  reportContent: chartInitObj,
  reportDetails: null,
  reportDetailsLoading: false,
  reportDetailsErrorMessage: "",
  showTableIsValid: false,
  //multi-chart
  NumberOfCharts: [],
  chartNumber: 0,
  chartContent: [],
  deleteMultipleReportsError: false,
};

const report = (state = init, action) => {
  switch (action.type) {
    case GET_REPORTS_DATA:
      return {
        ...state,
        reportsDataLoading: true,
      };
    case GET_REPORTS_DATA_SUCCESS:
      return {
        ...state,
        reportsData: action.data,
        reportsDataLoading: false,
      };
    case GET_REPORTS_DATA_FAILED:
      return {
        ...state,
        reportsDataLoading: false,
        reportsDataError: action.message,
      };
    case SAVE_REPORT_CONTENT:
      return {
        ...state,
        saveReportContentLoading: true,
      };
    case SAVE_REPORT_CONTENT_SUCCESS:
      return {
        ...state,
        saveReportContentLoading: false,
      };
    case SAVE_REPORT_CONTENT_FAILED:
      return {
        ...state,
        saveReportContentLoading: false,
        saveReportContentError: action.message,
      };
    case SAVE_REPORT:
      return {
        ...state,
        saveReportLoading: true,
      };
    case SAVE_REPORT_SUCCESS:
      return {
        ...state,
        saveReportLoading: false,
        saveReportSuccessIsValid: true,
        NumberOfCharts: [],
        chartNumber: 0,
        chartContent: [],
        reportInfo: reportInfoInit,
      };
    case SET_SAVE_REPORT_SUCCESS_INVALID:
      return {
        ...state,
        saveReportSuccessIsValid: false,
      };
    case SAVE_REPORT_FAILED:
      return {
        ...state,
        saveReportLoading: false,
        saveReportError: action.message,
      };
    case SET_SAVE_REPORT_ERROR_NULL:
      return {
        ...state,
        saveReportError: null,
      };
    case SET_TRENDS_REPORTS_PAGINATION:
      return {
        ...state,
        reportsPagination: action.data,
      };
    case GET_SALES_DATA:
      return {
        ...state,
        salesDataLoading: true,
      };
    case GET_SALES_DATA_SUCCESS:
      return {
        ...state,
        salesData: action.data,
        salesDataLoading: false,
      };
    case GET_SALES_DATA_FAILED:
      return {
        ...state,
        salesDataError: action.message,
        salesDataLoading: false,
      };
    case SET_NEW_REPROT_VALID:
      return {
        ...state,
        newReportIsValid: true,
      };
    case SET_NEW_REPROT_IN_VALID:
      return {
        ...state,
        newReportIsValid: false,
      };
    case SET_CHART_COMMENT_VALUE:
      return {
        ...state,
        chartCommentValue: action.value,
      };
    case SET_ADD_NEW_CHART_VALID:
      return {
        ...state,
        addNewChartIsValid: true,
      };
    case SET_ADD_NEW_CHART_IN_VALID:
      return {
        ...state,
        addNewChartIsValid: false,
      };
    case SET_REPORT_STEP:
      return {
        ...state,
        step: action.stepValue,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              step: action.stepValue,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_REPORT_INFO:
      return {
        ...state,
        reportInfo: action.data,
      };
    case SET_REPORT_CONTENT:
      return {
        ...state,
        reportContent: action.data,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              reportContent: action.data,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_LOCATIONS_OPTIONS:
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              locationOptionSelection: action.data,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_PRICE_BRACKET_OPTIONS:
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              bracketTypeOptionSelection: action.data,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_CHART_LABELS:
      return {
        ...state,
        chartLabels: action.data,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              chartLabels: action.data,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_CHART_DATA_LOADING:
      return {
        ...state,
        chartDataLoading: true,
      };
    case SET_CHART_DATA:
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              chartData: action.data,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_REPORT_INFO_TITLE_IS_VALID:
      return {
        ...state,
        reportInfoTitleIsValid: action.value,
      };
    case SET_REPORT_CONTENT_TITLE_IS_VALID:
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              reportContentTitleIsValid: action.value,
            };
          } else {
            return item;
          }
        }),
      };
    case GET_TREND_REPORT_DETAILS:
      return {
        ...state,
        reportDetailsLoading: true,
      };
    case GET_TREND_REPORT_DETAILS_SUCCESS:
      return {
        ...state,
        reportDetailsLoading: false,
        reportDetails: action.data,
      };
    case GET_TREND_REPORT_DETAILS_FAILED:
      return {
        ...state,
        reportDetailsLoading: false,
        reportDetailsErrorMessage: action.message,
      };
    case SET_TREND_REPORT_DETAILS_NULL:
      return {
        ...state,
        reportDetails: null,
      };
    case SET_MARKET_ANALYSIS_IS_VALID:
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              marketAnalysisIsValid: action.value,
            };
          } else {
            return item;
          }
        }),
      };
    case SET_SHOW_TABLE_IS_VALID: {
      return {
        ...state,
        chartContent: state.chartContent.map((item) => {
          if (item.chartId === action.chartId) {
            return {
              ...item,
              showTableIsValid: action.value,
            };
          } else {
            return item;
          }
        }),
      };
    }
    case SET_NEW_CHART: {
      return {
        ...state,
        NumberOfCharts: [
          `NEW_CHART-${action.chartNumber}`,
          ...state.NumberOfCharts,
        ],
        chartNumber: action.chartNumber,
        chartContent: [
          ...state.chartContent,
          {
            chartId: `NEW_CHART-${action.chartNumber}`,
            reportContent: chartInitObj,
            showTableIsValid: false,
            marketAnalysisIsValid: false,
            step: 1,
            chartLabels: [],
            chartData: [],
            reportContentTitleIsValid: true,
            chartDataLoading: false,
            locationOptionSelection: reportCitiesOptions,
            bracketTypeOptionSelection: priceBracketOptions,
          },
        ],
      };
    }
    case REMOVE_CHART: {
      return {
        ...state,
        NumberOfCharts: action.remainingCharts,
        chartContent: state.chartContent.filter(
          (item) => item.chartId !== action.singleChartContent.chartId
        ),
      };
    }
    case REMOVE_ALL_CHARTS: {
      return {
        ...state,
        NumberOfCharts: [],
        chartNumber: 0,
        chartContent: [],
        reportInfo: reportInfoInit,
        reportInfoTitleIsValid: true,
      };
    }
    case DELETE_MULTIPLE_REPORTS:
      return {
        ...state,
        reportsDataLoading: true,
      };
    case DELETE_MULTIPLE_REPORTS_SUCCESS:
      return {
        ...state,
        reportsDataLoading: false,
        reportsData: action.data,
      };
    case DELETE_MULTIPLE_REPORTS_FAILED:
      return {
        ...state,
        reportsDataLoading: false,
        reportsData: state.reportsData,
        deleteMultipleReportsError: true,
      };
    case HIDE_DELETE_MULTIPLE_REPORTS_ERROR:
      return {
        ...state,
        deleteMultipleReportsError: false,
      };
    default:
      return state;
  }
};

export default report;
